import { useDefaultLayout } from '@cutover/react-ui'
import { Layout } from 'main/components/layout'
import { ApplicationIntelligenceHeader } from './application-intelligence-header/application-intelligence-header'

export const ApplicationIntelligenceLayout = () => {
  useDefaultLayout({
    filterPanel: false,
    subHeader: false,
    rightNav: false
  })

  return <Layout header={<ApplicationIntelligenceHeader />} filter={null} />
}
