import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { isArray, isObject } from 'lodash'

import { ColumnConfig, LoadingPanel, NoResourceFound, Pill, Table } from '@cutover/react-ui'
import { useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useDataSourceValuesQuery } from 'main/services/queries/use-data-source-values'
import { useAccount, useAccountCustomFields } from 'main/services/api/data-providers/account/account-data'
import { useSetDefaultDatasource } from './use-default-workspace-datasource'
import { useFilters } from 'main/components/shared/filter/filter-provider'
import { CustomField, DataSourceValues } from 'main/services/queries/types'
import { useRouting } from 'main/services/routing'

export const WorkspaceDataSourceDetails = () => {
  const navigate = useNavigate()
  const { toWorkspace } = useRouting()

  const { account } = useAccount()
  const accountId = account?.id

  const { customFieldId } = useParams()
  const { isLoading, customFieldsLookup } = useAccountCustomFields()

  if (isLoading) {
    return <LoadingPanel />
  }

  if (accountId && customFieldId && customFieldsLookup) {
    const customField = customFieldsLookup[parseInt(customFieldId)]

    if (customField && isCustomFieldApplicable(customField, accountId)) {
      return (
        <WorkspaceDataSourceDetailsInner
          accountId={accountId}
          customField={customField}
          customFieldsLookup={customFieldsLookup}
        />
      )
    } else {
      navigate(toWorkspace({ accountSlug: account.slug }))
      return null
    }
  }

  return null
}

const WorkspaceDataSourceDetailsInner = ({
  accountId,
  customField,
  customFieldsLookup
}: {
  accountId: number
  customField: CustomField
  customFieldsLookup: any
}) => {
  const { openRightPanel } = useSetActiveRightPanelState()
  const { filters } = useFilters()

  useSetDefaultDatasource({ accountId, customFieldId: customField.id })

  const customAttributes = Object.values(customFieldsLookup)
    ?.map(field => {
      const cf = field as CustomField
      if (cf.source_custom_field_id === customField.id) {
        return cf.display_name
      }
    })
    .filter(Boolean)

  const { data, isLoading } = useDataSourceValuesQuery({
    query: filters.q?.toString() || '',
    cf_id: customField.id,
    account_id: accountId
  })

  const tableColumns = useMemo(() => buildColumns(customAttributes as string[]), [customAttributes])
  const tableData = useMemo(() => buildData(data), [data])

  // Filter data, first on search then template filter
  const filteredTableData = filters.q
    ? tableData?.filter(value => value.key.toLowerCase().includes((filters.q?.toString() || '').toLocaleLowerCase()))
    : filters.t_filter
    ? filters.t_filter === 'has_templates'
      ? tableData?.filter(value => value.templates > 0)
      : tableData?.filter(value => value.templates === 0)
    : tableData

  const handleClickRow = ({ datum }: { datum: DataSourceValuesRow }) => {
    openRightPanel({ type: 'runbook-templates-with-datasources', key: datum.key, customField: customField })
  }

  if (isLoading) {
    return <LoadingPanel />
  } else if (filteredTableData?.length > 0) {
    return <Table columns={tableColumns} data={filteredTableData} onClickRow={handleClickRow} />
  } else {
    return <NoResourceFound context="data source value" />
  }
}

const buildColumns = (customAttributes: string[] | undefined): ColumnConfig<any>[] => {
  return [
    {
      header: 'Search value',
      property: 'key',
      render: (datum: DataSourceValuesRow) => <strong>{datum.key}</strong>
    },
    {
      header: 'Templates',
      property: 'templates',
      render: (datum: DataSourceValuesRow) =>
        datum.templates > 0 ? (
          <Pill color="primary" label={`${datum.templates} Template${datum.templates > 1 ? 's' : ''}`} />
        ) : (
          'None'
        )
    },
    ...(customAttributes?.map((attribute: string) => ({
      header: attribute,
      property: attribute,
      render: (datum: any) => {
        const value = datum[attribute]

        if (isArray(value)) {
          return (
            <ul>
              {value.map(i => (
                <li key={i}>{i}</li>
              ))}
            </ul>
          )
        } else if (isObject(value)) {
          console.warn(`Value of type object is not supported for key "${attribute}"`, value)
          return ''
        } else {
          return value
        }
      }
    })) || [])
  ]
}

type DataSourceValuesRow = {
  id: number
  key: string
  templates: number
}

const buildData = (data?: DataSourceValues): DataSourceValuesRow[] => {
  const templatesData = data?.data_source_values_map.templates
  return (
    data?.data_source_values.map(value => {
      return {
        id: value.id,
        key: value.key,
        templates: templatesData?.[value.id] || 0,
        ...value.values
      }
    }) || []
  )
}

// Note: might need to rename to something like isDataViewEnabled and use this as a filter function in the workspace-header too
const isCustomFieldApplicable = (customField: CustomField, selectedAccountId: number) => {
  const {
    field_type: { slug },
    global,
    account_id
  } = customField

  return (slug === 'searchable' || slug === 'multi_searchable') && (global || selectedAccountId === account_id)
}
