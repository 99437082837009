import { useMemo } from 'react'
import { format as formatDate, fromUnixTime, getUnixTime } from 'date-fns'

import { IconName, TaskTypeIconName } from '@cutover/icons'
import { duration as durationFormatter, TaskItemDateData } from '@cutover/react-ui'
import {
  FieldValue,
  LinkedResourceCurrentStatus,
  Run,
  RunbookComponent,
  RunbookShowRunbook,
  RunbookTeam,
  RunbookVersion,
  StreamListStream,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'
import { RunbookVersionUser } from 'main/services/queries/use-runbook-versions'
import {
  calculateDayNumber,
  createDateDisplayForScheduled,
  createDateDisplayForUnscheduled
} from './task-item-duration-helpers'
import { useLanguage } from 'main/services/hooks'
import { useTaskListCustomFieldPillLabels } from 'main/components/workspace/pages/runbooks-list/use-custom-field-pill-labels'
import { RunbookTeamModel } from 'main/data-access'

type TaskListItemPropsType = {
  showCreateAfter: boolean
  canUpdate: boolean
  critical?: boolean
  commentsCountState: number
  first: boolean
  float?: number
  iconDisabled: boolean
  isLoading: boolean
  nextTaskId?: number
  parentStream?: StreamListStream
  previousTask?: TaskListTask
  previousTaskType?: TaskType
  run?: Run | null
  runbookComponent?: RunbookComponent
  runbookCurrentVersion: RunbookVersion
  runbookVersion: RunbookVersion
  runbook: RunbookShowRunbook
  stream: StreamListStream
  task: TaskListTask
  taskType: TaskType
  teams: RunbookTeam[]
  users: RunbookVersionUser[]
  fieldValues: FieldValue[]
  activeTimezone?: string | null
}

export const useTaskListItemProps = ({
  activeTimezone,
  showCreateAfter,
  canUpdate,
  critical,
  commentsCountState,
  first,
  float,
  nextTaskId,
  parentStream,
  previousTask,
  previousTaskType,
  run,
  runbookComponent,
  runbookCurrentVersion,
  runbookVersion,
  stream,
  task,
  taskType,
  teams,
  users,
  iconDisabled,
  fieldValues,
  runbook,
  isLoading
}: TaskListItemPropsType) => {
  const { t } = useLanguage('runbook', { keyPrefix: 'taskListItem' })
  const streamsInheritColor = false // PR-CMNT: when was this removed? is it still necessary?
  const parentStreamName = parentStream ? `${parentStream.name} / ` : ''
  const userToTeamLookup = RunbookTeamModel.useGetAllByUserIdLookup()

  const { customFieldPillLabels } = useTaskListCustomFieldPillLabels({
    fieldValues,
    timezone: runbook.timezone as string
  })

  const linkedResourceStatus = task.linked_resource?.current_status?.linked_resource

  return useMemo(
    () => ({
      avatars: avatars(users, teams, userToTeamLookup),
      suffixLabel: suffixLabel(
        task.completion_type,
        task.stage,
        task.start_display,
        task.end_display,
        task.duration,
        linkedResourceStatus
      ),
      isLate: isLate(task, runbookVersion.stage),
      durationPlanned: task.duration,
      durationDiff: durationDiff(
        task.stage,
        task.completion_type,
        task.end_display,
        task.start_display,
        task.duration,
        task.end_fixed,
        runbookCurrentVersion.timing_mode,
        runbookVersion.start_planned,
        t('unscheduledDayLabel'),
        t('dueLabel')
      ),
      showSkip: canSkip(canUpdate, task.stage, task.completion_type, runbookVersion.stage, isLoading),
      startableOrDefault: startableOrDefault(task.stage),
      startDiff: startDiff(task.start_display, task.start_latest_planned, runbookVersion.stage),
      integrationImageUrl: integrationImgUrl(taskType.integration_action_items),
      dateData: taskDateData({
        startDisplay: task.start_display,
        versionStartDisplay: runbookCurrentVersion.start_display,
        versionTimingMode: runbookCurrentVersion.timing_mode,
        dayLabel: t('unscheduledDayLabel'),
        previousStartDisplay: previousTask?.start_display,
        activeTimezone
      }) as TaskItemDateData,

      // for date display and border top on list item when different day from previous task
      showBorderTop: showBorderTop(first, task.start_display, previousTask?.start_display),
      disabled: disabled(task.completion_type, task.stage, runbookVersion.stage, linkedResourceStatus),
      namePrefixIcon: namePrefixIcon(taskType.sub_icon, runbookComponent),
      errors:
        task.errors.length > 0
          ? task.errors.map(apiError => {
              return t('errors.' + apiError)
            })
          : undefined,

      criticalPathIndicator: critical
        ? {
            border: (float ? 'dashed' : 'solid') as 'dashed' | 'solid',
            tooltip: float ? t('criticalPathWithFloat', { float: durationFormatter(float, 2) }) : t('criticalPath')
          }
        : undefined,
      internalId: task.internal_id,
      isLateStartFixed: task.start_fixed ? task.start_fixed < task.start_display : undefined,
      name: task.name,
      nameBold: taskType.highlight,
      persistCommentsButton: task.comments_count > 0 || commentsCountState > 0,
      showAvatarsAsPill: runbook.settings_team_in_task_list,
      startActual: task.start_display, // We don't have task.start_actual in list serializer, but this is same when inprog
      stage: task.stage,
      streamName: `${parentStreamName}${stream.name}`,
      taskRtoMarkerLabel: taskRtoMarkerLabel(
        task.id,
        runbookVersion.rto_start_task_id,
        runbookVersion.rto_end_task_id,
        t('rtoStartTaskLabel'),
        t('rtoEndTaskLabel')
      ),
      customFieldPillLabels,
      iconProps: {
        color: color(stream?.color, streamsInheritColor, parentStream?.color),
        disabled: iconDisabled,
        disableNotify: task.disable_notify,
        icon: taskTypeIcon({ icon: taskType.icon, stage: task.stage, isTemplate: task.linked_resource?.is_template }),
        inProgress: linkedResourceStatus === 'paused' ? false : task.stage === 'in-progress',
        isOpaque: task.stage === 'complete',
        predecessorCountLabel: predecessorCountLabel(task.predecessor_ids, previousTask?.id),
        successorCountLabel: successorCountLabel(task.successor_ids, nextTaskId),
        stageIcon: stageIconName({
          completionType: task.completion_type,
          stage: task.stage,
          versionStage: runbookVersion.stage,
          startFixed: task.start_fixed,
          runType: run?.run_type,
          linkedResourceStatus
        }),
        hoverIcon: showCreateAfter ? ('add' as IconName) : undefined,
        withConnectors: withConnectors(task, previousTask, previousTaskType)
      }
    }),
    [
      showCreateAfter,
      canUpdate,
      critical,
      first,
      float,
      nextTaskId,
      parentStream,
      previousTask,
      previousTaskType,
      run,
      runbookComponent,
      runbookCurrentVersion,
      taskRtoMarkerLabel,
      runbookVersion,
      stream,
      task,
      taskType,
      teams,
      users,
      iconDisabled,
      runbook,
      customFieldPillLabels,
      streamsInheritColor
    ]
  )
}

const color = (streamColor: string, inheritParentColor: boolean, parentStreamColor?: string) =>
  inheritParentColor ? parentStreamColor || streamColor : streamColor
const successorCountLabel = (successorIds: number[], nextTaskId?: number) =>
  successorIds.filter(id => id !== nextTaskId).length ? successorIds.length : undefined
const predecessorCountLabel = (predecessorIds: number[], previousTaskId?: number) =>
  predecessorIds.filter(id => id !== previousTaskId).length ? predecessorIds.length : undefined
const withConnectors = (task: TaskListTask, previousTask?: TaskListTask, previousTaskType?: TaskType) => {
  if (!previousTask?.id || !task.predecessor_ids.includes(previousTask?.id) || !previousTaskType) return undefined

  const { stage, start_fixed, start_display, linked_resource } = task
  const { end_display: prevEndDisplay, stage: prevStage } = previousTask
  const { icon: prevIcon } = previousTaskType

  return {
    previousIcon: taskTypeIcon({
      icon: prevIcon,
      stage: prevStage,
      isTemplate: linked_resource?.is_template
    }),
    bold: stage === 'complete',
    dotted: stage !== 'complete' && !!start_fixed && !!prevEndDisplay && prevEndDisplay <= start_display
  }
}

export const taskTypeIcon = ({
  icon,
  stage,
  isTemplate
}: {
  icon: TaskType['icon']
  stage: TaskListTask['stage']
  isTemplate?: boolean
}): TaskTypeIconName => {
  const baseIcon = icon?.split('_')?.[1] || 'normal'
  const stageSuffix = stage === 'complete' ? '-100' : ''
  const templateSuffix = isTemplate ? '-dashed' : ''
  return `${baseIcon}${stageSuffix}${templateSuffix}` as TaskTypeIconName
}

export const stageIconName = ({
  completionType,
  stage,
  startFixed,
  versionStage,
  runType,
  linkedResourceStatus
}: {
  completionType: TaskListTask['completion_type']
  stage: TaskListTask['stage']
  startFixed: TaskListTask['start_fixed']
  versionStage?: RunbookVersion['stage']
  runType?: Run['run_type']
  linkedResourceStatus?: LinkedResourceCurrentStatus['linked_resource']
}): IconName | undefined => {
  if (
    completionType !== 'complete_skipped' &&
    startFixed &&
    stage === 'startable' &&
    versionStage === 'active' &&
    runType === 'live' &&
    startFixed * 1000 > Date.now()
  )
    return 'fixed-start'

  if (linkedResourceStatus === 'paused') return 'pause'
  if (linkedResourceStatus === 'cancelled') return 'abandoned'
  if (completionType === 'complete_skipped') return 'skipped'
  if (completionType === 'complete_abandoned') return 'abandoned'

  switch (stage) {
    case 'startable':
      return 'play-arrow'
    case 'in-progress':
      return 'more-horizontal' // this differs from the main task list which shows a dashed check icon
    case 'complete':
      return 'check-solid'
    case 'default':
      return undefined // we don't show an icon when not startable
  }
}

const avatars = (
  users: RunbookVersionUser[],
  teams: RunbookTeam[],
  userToTeamLookup: Record<number, RunbookTeam[]>
) => {
  const usersAndTeams = [...users, ...teams]
  if (usersAndTeams.length === 0) return undefined

  const avatars = usersAndTeams.map(subject => {
    const isTeam = subject.hasOwnProperty('team_id')
    const teamNames = isTeam ? null : userToTeamLookup[subject.id]?.map(team => team.name).join(', ')

    return {
      tooltip: isTeam ? true : `${subject.name}${teamNames ? ` (${teamNames})` : ''}`,
      subject
    }
  })

  return avatars.length === 1 ? avatars[0] : avatars
}
const suffixLabel = (
  completionType: TaskListTask['completion_type'],
  stage: TaskListTask['stage'],
  startDisplay: TaskListTask['start_display'],
  endDisplay: TaskListTask['end_display'],
  duration: TaskListTask['duration'],
  linkedResourceStatus?: LinkedResourceCurrentStatus['linked_resource']
) => {
  if (linkedResourceStatus === 'cancelled') return 'cancelled'
  if (completionType === 'complete_skipped') return 'skipped' // TODO: translations
  if (completionType === 'complete_abandoned') return 'abandoned'
  if (completionType === 'complete_normal' && stage === 'complete')
    return durationFormatter(endDisplay - startDisplay, 2)
  if (stage === 'in-progress') return durationFormatter(Math.max(endDisplay - startDisplay, duration), 2)
  if (duration) return durationFormatter(duration, 2)
  return undefined
}

export const isLate = (task: TaskListTask, runbookVersionStage: RunbookVersion['stage']) => {
  if (runbookVersionStage === 'planning' || runbookVersionStage === 'complete' || task.stage === 'complete') {
    return false
  }

  const endPlanned = (task.start_latest_planned || task.start_display) + task.duration
  const endLatestPlanned = task.end_fixed && task.end_fixed > endPlanned ? task.end_fixed : endPlanned
  const nowInUnix = getUnixTime(new Date())

  switch (task.stage) {
    case 'in-progress':
      return nowInUnix > endLatestPlanned
    case 'startable':
      return nowInUnix + task.duration > endLatestPlanned
    default:
      return !!(task.start_latest_planned && task.start_latest_planned < nowInUnix)
  }
}

export const inProgDurationDiff = (startDisplay: number, endDisplay: number, duration: number) => {
  const durationDiff = Math.max(endDisplay - startDisplay, duration) - duration
  const taskIsLate = durationDiff > 0
  return {
    color: taskIsLate ? 'warning' : 'text-light',
    text: `${taskIsLate ? '+' : ''}${durationFormatter(durationDiff, 2)}`
  }
}

export const completeDurationDiff = (startDisplay: number, endDisplay: number, duration: number) => {
  const durationDiff = endDisplay - startDisplay - duration
  const isLate = durationDiff >= 0
  return durationDiff === 0
    ? undefined
    : {
        color: isLate ? 'warning' : 'success',
        text: `${isLate ? '+' : '-'}${durationFormatter(durationDiff, 2)}`
      }
}

const durationDiff = (
  stage: TaskListTask['stage'],
  completionType: TaskListTask['completion_type'],
  endDisplay: TaskListTask['end_display'],
  startDisplay: TaskListTask['start_display'],
  duration: TaskListTask['duration'],
  endFixed: TaskListTask['end_fixed'],
  timingMode: RunbookVersion['timing_mode'],
  runbookStartPlanned: RunbookVersion['start_planned'],
  unscheduledDayLabel: string,
  dueLabel: string
) => {
  if (stage === 'in-progress') {
    return inProgDurationDiff(startDisplay, endDisplay, duration)
  }

  if (stage === 'complete' && completionType === 'complete_normal') {
    return completeDurationDiff(startDisplay, endDisplay, duration)
  }

  if (endFixed && runbookStartPlanned) {
    const endFixedDate = new Date(endFixed * 1000)
    const dueTaskDay = calculateDayNumber({
      taskValue: endFixed,
      dayZeroValue: runbookStartPlanned
    })
    const dueDateDisplay =
      timingMode === 'scheduled'
        ? formatDate(endFixedDate, 'd MMM HH:mm')
        : `${unscheduledDayLabel} ${dueTaskDay} ${formatDate(endFixedDate, 'HH:mm')}`
    return {
      text: `${duration ? '•' : ''} ${dueLabel} ${dueDateDisplay}`,
      color: 'text-light'
    }
  }
}
export const canSkip = (
  canUpdate: boolean,
  stage: TaskListTask['stage'],
  completionType: TaskListTask['completion_type'],
  versionStage: RunbookVersion['stage'],
  isLoading: boolean
) =>
  !isLoading &&
  canUpdate &&
  completionType === 'complete_normal' &&
  versionStage === 'active' &&
  ['default', 'startable', 'in-progress'].includes(stage)

const startableOrDefault = (stage: TaskListTask['stage']) => ['startable', 'default'].includes(stage)
const startDiff = (
  startDisplay: TaskListTask['start_display'],
  startLatestPlanned: TaskListTask['start_latest_planned'],
  versionStage: RunbookVersion['stage']
) => (versionStage !== 'planning' ? (startDisplay ?? 0) - (startLatestPlanned ?? 0) : undefined)
const integrationImgUrl = (integrationActionItems: TaskType['integration_action_items']) => {
  const integrationActionItem = integrationActionItems.length > 0 ? integrationActionItems[0] : undefined
  if (!integrationActionItem) return undefined

  const {
    image_url: imageUrl,
    integration_action: integrationAction,
    integration_setting: integrationSetting
  } = integrationActionItem
  const integrationType = integrationAction ? integrationAction.split('::')[1] : 'MissingIntegration'

  if (imageUrl && integrationAction) {
    return imageUrl
  }

  if (integrationSetting && integrationSetting.image_url) {
    return integrationSetting.image_url
  }

  const image_url = 'img/integrations/' + integrationType + '.png'
  // Note: the integrationImageCache was previously caching this path to avoid repeated requests to check if the above path exists
  // Left this code commented here as will likely be needed again soon
  // const default_image_url = 'img/integrations/MissingIntegration.png'
  // const http = new XMLHttpRequest()
  // http.open('HEAD', image_url, false)
  // http.send()
  // const path = http.status !== 404 ? image_url : default_image_url
  // return path
  return image_url
}

const taskDateData = ({
  startDisplay,
  versionStartDisplay,
  versionTimingMode,
  dayLabel,
  previousStartDisplay,
  activeTimezone
}: {
  startDisplay: TaskListTask['start_display']
  versionStartDisplay: RunbookVersion['start_display']
  versionTimingMode: RunbookVersion['timing_mode']
  dayLabel: string
  previousStartDisplay?: TaskListTask['start_display']
  activeTimezone?: string | null
}) =>
  versionTimingMode === 'scheduled'
    ? createDateDisplayForScheduled({
        startDisplay,
        activeTimezone,
        previousTaskStartDisplay: previousStartDisplay
      })
    : createDateDisplayForUnscheduled({
        taskStartDate: startDisplay,
        runbookStartPlanned: versionStartDisplay ?? 0,
        previousStartDisplay: previousStartDisplay,
        dayLabel
      })

const showBorderTop = (
  first: boolean,
  startDisplay?: TaskListTask['start_display'],
  previousStartDisplay?: TaskListTask['start_display']
) =>
  !!(
    !first &&
    previousStartDisplay &&
    startDisplay && // current task start display
    formatDate(fromUnixTime(previousStartDisplay), 'd LLL') !== formatDate(fromUnixTime(startDisplay), 'd LLL')
  )

const disabled = (
  completionType: TaskListTask['completion_type'],
  stage: TaskListTask['stage'],
  versionStage: RunbookVersion['stage'],
  linkedResourceStatus?: LinkedResourceCurrentStatus['linked_resource']
) =>
  linkedResourceStatus === 'paused' ||
  linkedResourceStatus === 'cancelled' ||
  completionType === 'complete_skipped' ||
  completionType === 'complete_abandoned' ||
  (stage === 'default' && versionStage === 'active')

const namePrefixIcon = (subIcon: TaskType['sub_icon'], taskRunbookComponent?: RunbookComponent) => {
  const isSnippet = taskRunbookComponent?.source_runbook_template_type === 'snippet'

  return (isSnippet ? 'snippet' : subIcon ? subIcon.replace(/^icon-/, '') : undefined) as IconName | undefined
}

const taskRtoMarkerLabel = (
  taskId: number,
  rtoStartTaskId: number | null,
  rtoEndTaskId: number | null,
  labelStart: string,
  labelEnd: string
) => {
  if (taskId === rtoStartTaskId) return labelStart
  if (taskId === rtoEndTaskId) return labelEnd
}
