import { memo, useCallback, useDeferredValue, useMemo, useRef } from 'react'
import { Virtuoso } from 'react-virtuoso'
import { useEffectOnce, useWindowSize } from 'react-use'
import { useSearchParams } from 'react-router-dom'

import {
  Box,
  Menu,
  NoResourceFound,
  Text,
  useFilterPanelOpenState,
  useRightPanelLayoutOpenState,
  useUpdateEffect
} from '@cutover/react-ui'
import { useClearAllFilterState } from 'main/recoil/data-access'
import { TaskListHeader } from './task-list-header'
import { useRootTaskCreateForm } from './task-item/task-item-create'
import { useTaskListHotkeys } from './use-task-list-hotkeys'
import { TaskListItem } from './task-item/task-list-item'
import { useRightPanelTypeValue, useToggleRightPanel } from 'main/components/layout/right-panel'
import { ActiveRunbookVersionModel, RunbookViewModel, TaskModel } from 'main/data-access'
import { useStickyDates } from './use-sticky-dates'

const TASK_LIST_HEADERS_HEIGHT = 200
const TASK_ITEM_HEIGHT = 52

// TODO: pull this out for suspense to not render the component content until tasks exist
export const TaskList = memo(() => {
  useTaskListHotkeys()

  const runbookVersionId = ActiveRunbookVersionModel.useId()
  const clearAllFilters = useClearAllFilterState()
  const filteredTaskIds = TaskModel.useGetIds({ scope: 'filtered' })
  // This is a performance optimization which allow the filter panel to show changes before the task list
  // updates for a smoother experience when clicking filter controls on slower machines.
  const deferredFilteredTaskIds = useDeferredValue(filteredTaskIds)
  const { can: canCreateRootTask } = RunbookViewModel.usePermission('create:tasks')
  const rootTaskCreateForm = useRootTaskCreateForm({ taskCount: deferredFilteredTaskIds.length })
  const activeTimezone = RunbookViewModel.useGet('activeTimezone')

  return (
    <>
      {!canCreateRootTask && deferredFilteredTaskIds.length === 0 ? (
        <NoResourceFound context="task" clearAllFilters={clearAllFilters} />
      ) : (
        <>
          <TaskListHeader canCreateRootTask={canCreateRootTask} activeTimezone={activeTimezone} />
          {rootTaskCreateForm}
          <TaskListContent key={runbookVersionId} ids={deferredFilteredTaskIds} activeTimezone={activeTimezone} />
        </>
      )}
    </>
  )
})

const TaskListContent = memo(
  ({
    ids: filteredIds,
    activeTimezone
  }: {
    ids: number[]
    activeTimezone?: string | null
    criticalIds?: number[]
    floatLookup?: Record<number, number>
  }) => {
    const allTaskIds = TaskModel.useGetIds()
    const isHighlightMode = RunbookViewModel.useGet('highlightMode')
    const listIds = isHighlightMode ? allTaskIds : filteredIds
    const { height } = useWindowSize()
    const scrollSize = height - TASK_LIST_HEADERS_HEIGHT
    const { taskId: editingTaskId } = useRightPanelTypeValue('task-edit')
    const { taskId: commentsViewingTaskId } = useRightPanelTypeValue('runbook-comments')
    const [searchParams] = useSearchParams()
    const taskInternalId = searchParams.get('task')
    const selectedTask = TaskModel.useGetBy({ internal_id: Number(taskInternalId) })
    const toggleTaskEditPanel = useToggleRightPanel('task-edit')

    useEffectOnce(() => {
      if (selectedTask && selectedTask.id) {
        toggleTaskEditPanel({ taskId: selectedTask.id })
      }
    })

    const { handleScroll } = useStickyDates({
      wrapperId: 'react-task-list-wrapper',
      stickyDayId: 'react-sticky-day',
      stickyTimeId: 'react-sticky-time',
      stickyLocalTimeId: activeTimezone ? 'react-sticky-local-time' : null,
      itemHeight: TASK_ITEM_HEIGHT
    })

    return (
      <>
        <Box css="position: absolute; left: 48px; top: 79px; z-index: 1; display: flex; flex-direction: row;">
          <Text color="text-light" css="text-align: right; width: 52px;" id="react-sticky-day"></Text>
          <Text color="text-light" css="text-align: right; width: 46px;" id="react-sticky-time"></Text>
          {activeTimezone && (
            <Text color="text-light" css="text-align: right; width: 46px;" id="react-sticky-local-time"></Text>
          )}
        </Box>
        <TaskItemMenu />
        <Virtuoso
          style={{ height: `${scrollSize}px` }}
          totalCount={listIds.length}
          increaseViewportBy={scrollSize}
          defaultItemHeight={TASK_ITEM_HEIGHT}
          fixedItemHeight={TASK_ITEM_HEIGHT}
          onScroll={handleScroll}
          id="react-task-list-wrapper"
          itemContent={index => {
            const id = listIds[index]
            const previousTaskId = listIds[index - 1]
            const nextTaskId = listIds[index + 1]
            return (
              <TaskListItem
                isEditing={editingTaskId === id || commentsViewingTaskId === id}
                id={id}
                nextTaskId={nextTaskId}
                previousTaskId={previousTaskId}
                isFaded={isHighlightMode ? !filteredIds.includes(id) : undefined}
                activeTimezone={activeTimezone}
              />
            )
          }}
        />
      </>
    )
  }
)

const TaskItemMenu = memo(() => {
  const defaultRef = useRef<HTMLElement>(null)
  const { open, triggerRef, items, type, minWidth, maxWidth, maxHeight } = RunbookViewModel.useGet('menu')

  const clearMenu = RunbookViewModel.useAction('taskMenu:clear')
  const setMenuClosed = RunbookViewModel.useAction('taskMenu:close')

  const isRightPanelOpen = useRightPanelLayoutOpenState()
  const isFilterPanelOpen = useFilterPanelOpenState()

  const menuAlignment = useMemo(() => {
    if (type === 'options') {
      return 'end'
    } else {
      return 'start'
    }
  }, [type])

  useUpdateEffect(() => {
    if (open) {
      clearMenu()
      setMenuClosed()
    }
  }, [isRightPanelOpen, isFilterPanelOpen])

  const handleClose = useCallback((e: any) => {
    setMenuClosed()
    e?.reason !== 'blur' && clearMenu()
  }, [])

  return (
    <Menu
      isOpen={open}
      triggerRef={triggerRef ?? defaultRef}
      align={menuAlignment}
      items={items}
      //@ts-ignore
      onClose={handleClose}
      minWidth={minWidth}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      data-testid="task-item-menu"
      arrow
    />
  )
})
