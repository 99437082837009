import { memo, useCallback } from 'react'
import { useParams } from 'react-router-dom'

import { RightPanel, Text } from '@cutover/react-ui'
import { RunbookListItem } from 'main/components/shared/runbook-list-item'
import { useRightPanelTypeValue, useSetActiveRightPanelState } from 'main/components/layout/right-panel'
import { useLanguage } from 'main/services/hooks'
import { useRouting } from 'main/services/routing/hooks'
import { useRunbooks } from 'main/services/queries/use-runbooks'
import { CustomField } from 'main/services/queries/types'

export const RunbookTemplatesWithDatasourcesPanel = () => {
  const { key, customField } = useRightPanelTypeValue('runbook-templates-with-datasources')

  return customField && key ? <TemplatesWithDataSources filterKey={key} customField={customField} /> : null
}

type TemplatesWithDataSourcesProps = {
  filterKey: string
  customField: CustomField
}

const TemplatesWithDataSources = memo(({ filterKey, customField }: TemplatesWithDataSourcesProps) => {
  const { t } = useLanguage('dataSources', { keyPrefix: 'workspacePanel' })
  const { closeRightPanel } = useSetActiveRightPanelState()

  const { accountId: accountSlug } = useParams<{ accountId: string }>()
  const { toRunbook } = useRouting()

  const createLinkTo = useCallback(
    (id: number) => toRunbook({ accountSlug: accountSlug as string, runbookId: id }),
    [accountSlug]
  )

  const fieldOption = customField.field_options.find(option => option.name === filterKey)
  const fieldOptionId = fieldOption?.id

  const serveryQuery = {
    f: {
      [customField.id]: [fieldOptionId]
    },
    accountId: accountSlug,
    is_template: true
  }

  const { data } = useRunbooks(serveryQuery)

  return (
    <RightPanel title={t('title')} onClose={closeRightPanel}>
      <>
        {data?.runbooks?.length == 0 && <Text>{t('noTemplates')}</Text>}
        {data?.runbooks?.map(template => {
          return <RunbookListItem key={template.id} runbook={template} createLinkTo={createLinkTo} />
        })}
      </>
    </RightPanel>
  )
})
