import { forwardRef, KeyboardEvent, Ref, RefObject, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { FreeFocusInside } from 'react-focus-lock'
import { useMeasure } from 'react-use'
import { useCallbackRef, useMergeRefs } from 'use-callback-ref'

import { IconName } from '../../../../icon'
import { TextInputBase, TextInputBaseProps } from '../../../internal/text-input-base'
import { useSelectPopper } from '../../../select/use-select-popper'
import { SelectFieldBox, SelectMenuDrop, SelectOptionsList } from '../shared-components'
/*
 * Wrapping with FreeFocusInside so that react-focus-lock will ignore anything inside. In this case, the select menu dropdown.
 * This allows any open menu in a modal to close when clicking outside while also preserving the focus-trap.
 */

type SelectBaseProps = {
  noBottomPadding?: boolean
  isOpen: boolean
  inputValue?: string
  plain?: boolean
  icon?: IconName
  'data-testid'?: string
  getMenuProps: (ref: RefObject<HTMLElement>) => {}
  getInputProps: (ref: RefObject<HTMLInputElement>) => Partial<TextInputBaseProps>
  value?: any
  menuRoot?: HTMLElement
  onKeyDown?: (e: KeyboardEvent) => void
  onCreateOption?: (value: string) => void
  isAsync?: boolean
  minChars?: number
} & Omit<TextInputBaseProps, 'value' | 'icon'>

export const SelectBase = forwardRef<HTMLElement, SelectBaseProps>(
  (
    {
      'data-testid': dataTestId,
      isOpen,
      inputValue,
      icon,
      label,
      labelSuffix,
      onClickLabelSuffixButton,
      labelSuffixButtonText,
      hasError,
      noBottomPadding,
      inlineError,
      tooltipText,
      a11yTitle,
      shrinkLabel,
      labelProps,
      children,
      getMenuProps,
      value,
      getInputProps,
      onCreateOption,
      onKeyDown,
      plain,
      isAsync,
      minChars,
      ...props
    },
    ref
  ) => {
    const menuRoot = props.menuRoot ?? document.body
    const [fieldContainerRef, { width: fieldWidth }] = useMeasure()
    const [triggerEl, setTriggerEl] = useState<HTMLInputElement | null>(null)
    const localInputRef = useCallbackRef<HTMLInputElement>(null, el => {
      setTriggerEl(el)
    })

    const disclosureRef = useMergeRefs([ref, localInputRef]) as RefObject<HTMLInputElement>
    const popoverRef = useRef<HTMLElement>(null)

    const { styles, attributes, hasOpened } = useSelectPopper({
      value,
      isOpen,
      disclosureRef: triggerEl,
      computePositionOnRender: isAsync && minChars === 0,
      popoverRef: popoverRef.current,
      options: {
        hasIcon: !!icon,
        width: fieldWidth
      }
    })

    return (
      <FreeFocusInside>
        <SelectFieldBox
          flex={false}
          css={`
            > div {
              min-height: ${(onCreateOption || noBottomPadding) && 'unset'};
            }
            label {
              padding-bottom: ${(onCreateOption || noBottomPadding) && '0'};
            }
          `}
          ref={fieldContainerRef as Ref<HTMLElement>}
          onKeyDown={event => {
            onKeyDown?.(event)
            // backspacing with empty input otherwise deletes the selected items so we're intercepting
            if (event.key === 'Backspace' && inputValue === '') {
              event.stopPropagation()
            }
          }}
        >
          <TextInputBase
            data-testid={dataTestId}
            data-1p-ignore
            startIcon={icon}
            label={label}
            hasError={hasError}
            inlineError={inlineError}
            tooltipText={tooltipText}
            a11yTitle={a11yTitle}
            shrinkLabel={shrinkLabel}
            labelProps={labelProps}
            labelSuffix={labelSuffix}
            onClickLabelSuffixButton={onClickLabelSuffixButton}
            labelSuffixButtonText={labelSuffixButtonText}
            title={inputValue}
            plain={plain}
            ref={disclosureRef as any}
            {...getInputProps(disclosureRef)}
            {...props}
          />
          {createPortal(
            <SelectMenuDrop style={styles.popper} {...attributes.popper} {...getMenuProps(popoverRef)} isOpen={isOpen}>
              {hasOpened ? (
                <SelectOptionsList
                  isOpen={isOpen}
                  a11yTitle={(a11yTitle ?? label) + ' options'}
                  data-testid={dataTestId ? dataTestId + '-options' : undefined}
                >
                  {isOpen && children}
                </SelectOptionsList>
              ) : null}
            </SelectMenuDrop>,
            menuRoot
          )}
        </SelectFieldBox>
      </FreeFocusInside>
    )
  }
)
