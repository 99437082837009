import { Suspense, useEffect, useState } from 'react'
import { eventManager } from 'event-manager'
import { FormProvider, useForm } from 'react-hook-form'
import { RequireAllOrNone } from 'type-fest'

import { toSnakeCase } from '@cutover/api'
import { Box, EditPanel, LoadingPanel, Message, useNotify } from '@cutover/react-ui'
import { IntegrationsList } from './integrations-list'
import {
  EditRunbookIntegrationPayload,
  useRunbookIntegrationsQuery,
  useUpdateRunbookFieldValues
} from 'main/services/queries/use-runbook-integrations'
import { useLanguage } from 'main/services/hooks'
import {
  FieldValuesAttributes,
  RunbookIntegration,
  RunbookShowRunbook,
  StreamListStream,
  TaskListTask,
  TaskType
} from 'main/services/queries/types'
import { RunbookEditResponse } from 'main/services/api/data-providers/runbook-types'
import { StreamModel, TaskModel, TaskTypeModel } from 'main/data-access'

type IntegrationsPanelProps = {
  runbook: RunbookShowRunbook
  runbookVersionId: number
  onClose?: () => void
}

type IntegrationPanelDataProps = {
  taskLookup: Record<number, TaskListTask>
  taskTypeLookup: Record<number, TaskType>
  streamLookup: Record<number, StreamListStream>
}

export type EditIntegrationCustomFieldFormType = {
  runbook: {
    field_values: {
      [key: number | string]: FieldValuesAttributes
    }
  }
}

export const IntegrationsPanel = ({
  taskLookup,
  taskTypeLookup,
  streamLookup,
  ...props
}: IntegrationsPanelProps & RequireAllOrNone<IntegrationPanelDataProps>) => {
  return (
    <Suspense fallback={<LoadingPanel />}>
      {!taskLookup || !taskTypeLookup || !streamLookup ? (
        <IntegrationsPanelInnerWithGlobalStateData {...props} />
      ) : (
        <IntegrationsPanelInner {...(props as IntegrationPanelDataProps & IntegrationsPanelProps)} />
      )}
    </Suspense>
  )
}

const IntegrationsPanelInnerWithGlobalStateData = (props: IntegrationsPanelProps) => {
  const taskLookup = TaskModel.useGetLookup()
  const taskTypeLookup = TaskTypeModel.useGetLookup()
  const streamLookup = StreamModel.useGetLookup()

  return (
    <IntegrationsPanelInner
      taskLookup={taskLookup}
      taskTypeLookup={taskTypeLookup}
      streamLookup={streamLookup}
      {...props}
    />
  )
}

export const IntegrationsPanelInner = ({
  runbook,
  runbookVersionId,
  taskLookup,
  taskTypeLookup,
  streamLookup,
  onClose
}: IntegrationsPanelProps & IntegrationPanelDataProps) => {
  const { t } = useLanguage()
  const notify = useNotify()

  const { data, isLoading, isError } = useRunbookIntegrationsQuery({
    runbookId: runbook.id,
    runbookVersionId
  })

  const { id: runbookId, template_type: templateType, template_status: templateStatus, stage, archived } = runbook
  const integrationLinks = data?.integrationLinks
  const permissions = data?.meta?.permissions

  const canUpdateIntegrations = data && permissions && permissions.update.length > 0

  const disableIntegrationLinks =
    !canUpdateIntegrations &&
    (stage !== 'planning' || (templateType === 'default' && templateStatus !== 'template_draft'))

  const updateRunbookFieldValuesMutation = useUpdateRunbookFieldValues({ runbookId })

  const [runbookIntegrations, setRunbookIntegrations] = useState<RunbookIntegration[] | undefined>(integrationLinks)
  const [defaultValues, setDefaultValues] = useState({})
  const customFields = toSnakeCase(runbookIntegrations?.flatMap(ri => ri.customFields) || [])

  const buildDefaultCustomFieldFormValues = (): EditIntegrationCustomFieldFormType => {
    const fieldValues = toSnakeCase(runbookIntegrations?.flatMap(ri => ri.fieldValues) || [])
    const defaultValues: EditIntegrationCustomFieldFormType = { runbook: { field_values: {} } }
    customFields?.forEach(cf => {
      const fieldValue = fieldValues?.find(fv => fv.custom_field_id === cf.id)
      defaultValues.runbook.field_values[cf.id] = {
        id: fieldValue?.id,
        custom_field_id: cf.id,
        field_option_id: fieldValue?.field_option_id,
        remote_data_key_value: fieldValue?.remote_data_key_value,
        data_source_value_id: fieldValue?.data_source_value_id,
        value: fieldValue?.value
      }
    })
    return defaultValues
  }

  const methods = useForm<EditIntegrationCustomFieldFormType>({
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const handleClose = () => {
    eventManager.emit('close-runbook-integrations-panel')
    onClose?.()
  }

  const resetForm = () => {
    methods.reset(defaultValues)
  }

  const buildRunbookUpdatePayload = (data: EditIntegrationCustomFieldFormType) => {
    const fieldValues = []
    for (const [key, value] of Object.entries(data.runbook.field_values)) {
      const fv = value
      fv.custom_field_id = parseInt(key)
      fieldValues.push(fv)
    }
    return fieldValues
  }

  const onSubmitForm = async (data: EditIntegrationCustomFieldFormType) => {
    const payload: EditRunbookIntegrationPayload = {
      runbook: { field_values_attributes: buildRunbookUpdatePayload(data) }
    }

    updateRunbookFieldValuesMutation.mutate(payload, {
      onSuccess: (response: RunbookEditResponse) => {
        if (response.runbook.field_values.length > 0) {
          resetForm()
          response.runbook.field_values.forEach(fv => {
            methods.setValue(`runbook.field_values.${fv.custom_field_id}.id`, fv.id)
            methods.setValue(`runbook.field_values.${fv.custom_field_id}.value`, fv.value)
          })
          notify.success(t('runbook:integrationsPanel:customFieldForm:success'))
        } else {
          notify.error(t('runbook:integrationsPanel:error'))
        }
      },
      onError: () => {
        notify.error(t('runbook:integrationsPanel:error'))
      }
    })
  }

  useEffect(() => {
    if (updateRunbookFieldValuesMutation.isError) {
      updateRunbookFieldValuesMutation.error?.errorDetails?.forEach(error => {
        methods.setError(`runbook.field_values.${error?.meta?.custom_field_id}.value`, { message: error.message })
      })
    }
  }, [])

  useEffect(() => {
    setRunbookIntegrations(integrationLinks)
  }, [data])

  useEffect(() => {
    setDefaultValues(buildDefaultCustomFieldFormValues())
  }, [runbookIntegrations])

  useEffect(() => {
    resetForm()
  }, [defaultValues])

  return (
    <EditPanel
      title={t('runbook:integrationsPanel:title')}
      onClose={handleClose}
      onSubmit={() => methods.handleSubmit(onSubmitForm)()}
      onReset={resetForm}
      isDirty={methods.formState.isDirty}
      isSubmitting={methods.formState.isSubmitting}
      loading={isLoading}
      isError={isError}
    >
      <FormProvider {...methods}>
        <form>
          <Box direction="column" height="100%" css="flex-grow: 1; margin-top: -16px">
            {Object.keys(methods.formState?.errors?.runbook?.field_values || {})?.map(customFieldId => (
              <Box margin={{ bottom: '16px' }}>
                <Message
                  type="error"
                  key={customFieldId}
                  message={methods.formState?.errors?.runbook?.field_values?.[customFieldId]?.value?.message as string}
                />
              </Box>
            ))}
            <IntegrationsList
              disableLinks={disableIntegrationLinks || archived}
              disabled={archived}
              runbookIntegrations={runbookIntegrations || []}
              runbookId={runbookId}
              runbookVersionId={runbookVersionId}
              taskLookup={taskLookup}
              taskTypeLookup={taskTypeLookup}
              streamLookup={streamLookup}
            />
          </Box>
        </form>
      </FormProvider>
    </EditPanel>
  )
}
